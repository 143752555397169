import React, { useState } from 'react'
import parse from 'html-react-parser'
import { getImage } from 'gatsby-plugin-image'

import LinkOrButton from '../../LinkOrButton'
import BackgroundShape from '../../BackgroundShape'
import TitleMedium from '../../TitleMedium'
import HTMLContent from '../../HTMLContent'

import { TextKeyTableSection, ContentWrapper, HeadTitle, Subtitle, TextKeyTable } from './BlockTextKeyTable.styled'
import KeyTableSelector from '../../KeyTableSelector'
import KeysTable from '../../keysTable'

const BlockTextKeyTable = data => {
	const content = data.generated ? data.data : data

	const [selectedBrand, setSelectedBrand] = useState({ slug: '', name: '' })

	const handleSelectedBrand = brand => {
		setSelectedBrand(brand)
	}

	return (
		<>
			<TextKeyTableSection paddingBottom={content.paddingBottom} paddingTop={content.paddingTop}>
				<TextKeyTable>
					<KeyTableSelector selectedBrand={handleSelectedBrand} />
					<ContentWrapper>
						{content.h1Tag
							? content.subtitle && <HeadTitle>{parse(content.subtitle)}</HeadTitle>
							: content.subtitle && <Subtitle>{parse(content.subtitle)}</Subtitle>}
						{content.title && <TitleMedium dangerouslySetInnerHTML={{ __html: content.title }} />}
						<HTMLContent
							dangerouslySetInnerHTML={{
								__html: content.text
							}}
						/>
						{content.active && (
							<LinkOrButton type={content.linkOrButton} url={content.link?.url} text={content.linkButtonText} />
						)}
					</ContentWrapper>
				</TextKeyTable>
				{content.backgroundShape && <BackgroundShape />}
				{selectedBrand.slug && <KeysTable brand={selectedBrand.slug} showOfferteButton={false} />}
			</TextKeyTableSection>
		</>
	)
}

export default BlockTextKeyTable
