import React, { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'

import {
	KeyTable,
	KeyTableHeader,
	KeyTableSubtitle,
	KeyTableTitle,
	KeyTableFields,
	ContactLink,
	SelectField,
	ButtonSpec
} from './KeyTableSelector.styled'

import keyData from '../../../content/assets/data/possibleKeys.json'

const KeyTableSelector = ({ selectedBrand }) => {
	const [chosenBrand, setChosenBrand] = useState({ name: '' })

	const keyBrands = Object.entries(keyData).map(([slug, brand]) => {
		return { slug: brand.slug, name: brand.name }
	})

	const handleBrandChange = e => {
		const selectedBrandSlug = e.target.value
		const brandData = keyData[selectedBrandSlug] || {}

		setChosenBrand({ name: brandData.name, slug: selectedBrandSlug })
	}

	return (
		<>
			<KeyTable>
				<KeyTableHeader>
					<KeyTableTitle>Selecteer hier uw merk</KeyTableTitle>
					<KeyTableSubtitle>Bekijk de mogelijkheden voor uw voertuig.</KeyTableSubtitle>
				</KeyTableHeader>
				<KeyTableFields>
					<SelectField value={chosenBrand?.key} onChange={e => handleBrandChange(e)}>
						<option value="0">Selecteer uw Merk</option>
						{keyBrands.map((brand, index) => (
							<option value={brand.slug} key={index}>
								{brand.name}
							</option>
						))}
					</SelectField>
					<ButtonSpec
						variant="primary"
						onClick={() => selectedBrand(chosenBrand)}
						text="Bekijk info"
						padding="10px 20px"
						margin={isMobileOnly ? '10px 0 0 0' : '0'}
					>
						Bekijk info
					</ButtonSpec>
				</KeyTableFields>
			</KeyTable>
		</>
	)
}

export default KeyTableSelector
