import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import Section from '../../Section'

const TextKeyTableSection = styled(Section)`
	padding-top: ${props => props.paddingTop}px;
	padding-bottom: ${props => props.paddingBottom}px;
`

const ImageWrapper = styled.div`
	padding-bottom: 20px;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		padding: 0;
	}
`

const Image = styled(GatsbyImage)`
	z-index: 2;
	max-width: 100%;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		max-width: 500px;
	}
`

const ContentWrapper = styled.div`
	z-index: 2;
	/* max-width: 800px; */

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		/* max-width: 600px; */
		max-width: 50%;
		padding: ${props => (props.position ? '0 30px 0 0' : '0 0 0 30px')};
	}
`

const HeadTitle = styled.h1`
	margin: 0;
	padding-bottom: 10px;
	text-transform: uppercase;
	font-weight: 800;
	font-size: 1.2rem;
`

const Subtitle = styled.h5`
	margin: 0;
	padding-bottom: 10px;
	text-transform: uppercase;
	font-weight: 800;
`

const TextKeyTable = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		flex-direction: ${props => (props.position === 'right' ? 'row-reverse' : 'row')};
	}
`

export { TextKeyTableSection, ImageWrapper, Image, ContentWrapper, Subtitle, HeadTitle, TextKeyTable }
