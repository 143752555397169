import styled from 'styled-components'
import ButtonComponent from '../Button/Button'
import UniversalLink from '../UniversalLink'
import { OnClickButton } from '../Button/Button.styled'

const KeyTable = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 400px;
	width: 100%;
`

const KeyTableHeader = styled.div`
	background-color: #656565;
	color: #fff;
	padding: 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	display: flex;
	flex-direction: column;
`

const KeyTableTitle = styled.span``

const KeyTableSubtitle = styled.span`
	font-size: 0.8rem;
	color: #cccccc;
	margin: 0;
	line-height: 1;
`

const KeyTableFields = styled.div`
	background-color: #b0bec5;
	padding: 20px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	display: flex;
	flex-direction: column;
	min-height: 85px;
	z-index: 999;
	gap: 10px;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		flex-direction: row;
	}
`

const ContactLink = styled(UniversalLink)``

const SelectField = styled.select`
	border: none;
	border-radius: 5px;
	margin: 0 0 10px;
	/* height: 40px; */
	width: 100%;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		/* width: 18%; */
		height: auto;
		margin: 0;
	}
`

const ButtonSpec = styled(OnClickButton)``

export {
	KeyTable,
	KeyTableHeader,
	KeyTableSubtitle,
	KeyTableTitle,
	KeyTableFields,
	ContactLink,
	SelectField,
	ButtonSpec
}
